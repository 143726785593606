body {
  background-color: #ECE3D2;
  font-family: 'Arial'; }

a {
  color: #1E43FF;
  text-decoration: none; }
  a:hover {
    color: #1E43FF; }

nav ul {
  padding: 0; }

.project-icon-project-overview {
  float:left;
  width:100%;
}

.large-project-icon {
  width:50%
}

.parse-feedback-list-group {
  max-height:200px;
    overflow-y:auto;  
}

.media-files-missing-warning {
  width:100%;
  padding:0.2em;
  border:solid 4px rgb(65, 65, 65);
  background-color:rgb(243, 97, 0);
  font-weight: 800;
  margin:1em 0;
  cursor:pointer;
}

#liveset-upload-container {
  width: 80%;
  height: 40%;
  left:10%;
  text-align: center;
}

#liveset-upload-dropzone {
  padding:5em;
  border: 2px dashed rgb(102, 102, 102);
  border-radius: 5px;
  text-align: center;
}

#container {
  width: 94%;
  margin-left: 3%; }

#content {
  width:100%;
  background-color: #fff;
  padding: 2em;
  float: left; }

nav {
  border-bottom: 5px solid #000000;
  overflow: hidden;
  position: relative;
  margin-bottom: 2em; }
  nav ul {
    float: left;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center; }
    nav ul li {
      position: relative;
      display: block;
      float: left;
      list-style: none;
      margin: 0 0 0 1em;
      padding: 0.6em;
      display: block;
      line-height: 1.3em;
      font-size: 1.3em;
      font-weight: bold; }
      nav ul li a {
        color: #000000;
        font-weight: normal; }
        nav ul li a:hover {
          color: #000000; }